/* Color */

.color-brand {
  color: var(--color-brand);
}

.color-primary {
  color: var(--color-primary);
}

.color-secondary {
  color: var(--color-secondary);
}

.color-white {
  color: var(--color-white);
}

/** Background colors */

.bg-color-brand {
  background-color: var(--color-brand);
}

.bg-color-default {
  background-color: var(--background-color);
}

.bg-color-primary {
  background-color: var(--color-primary);
}

.bg-color-secondary {
  background-color: var(--background-color-secondary);
}

.bg-color-white {
  background-color: var(--color-white);
}

.bg-color-transparent {
  background-color: transparent;
}

/** Margin */

.margin-0-25 {
  margin: var(--spacing-0-25);
}

.margin-0-5 {
  margin: var(--spacing-0-5);
}

.margin-1 {
  margin: var(--spacing-1);
}

.margin-1-25 {
  margin: var(--spacing-1-25);
}

.margin-1-5 {
  margin: var(--spacing-1-5);
}

.margin-1-75 {
  margin: var(--spacing-1-75);
}

.margin-2 {
  margin: var(--spacing-2);
}

.margin-3 {
  margin: var(--spacing-3);
}

.margin-4 {
  margin: var(--spacing-4);
}

/** Margin X */

.margin-x-0-25 {
  margin-left: var(--spacing-0-25);
  margin-right: var(--spacing-0-25);
}

.margin-x-0-5 {
  margin-left: var(--spacing-0-5);
  margin-right: var(--spacing-0-5);
}

.margin-x-1 {
  margin-left: var(--spacing-1);
  margin-right: var(--spacing-1);
}

.margin-x-1-25 {
  margin-left: var(--spacing-1-25);
  margin-right: var(--spacing-1-25);
}

.margin-x-1-5 {
  margin-left: var(--spacing-1-5);
  margin-right: var(--spacing-1-5);
}

.margin-x-1-75 {
  margin-left: var(--spacing-1-75);
  margin-right: var(--spacing-1-75);
}

.margin-x-2 {
  margin-left: var(--spacing-2);
  margin-right: var(--spacing-2);
}

.margin-x-3 {
  margin-left: var(--spacing-3);
  margin-right: var(--spacing-3);
}

.margin-x-4 {
  margin-left: var(--spacing-4);
  margin-right: var(--spacing-4);
}

/** Margin Y */

.margin-y-0-25 {
  margin-top: var(--spacing-0-25);
  margin-bottom: var(--spacing-0-25);
}

.margin-y-0-5 {
  margin-top: var(--spacing-0-5);
  margin-bottom: var(--spacing-0-5);
}

.margin-y-1 {
  margin-top: var(--spacing-1);
  margin-bottom: var(--spacing-1);
}

.margin-y-1-25 {
  margin-top: var(--spacing-1-25);
  margin-bottom: var(--spacing-1-25);
}

.margin-y-1-5 {
  margin-top: var(--spacing-1-5);
  margin-bottom: var(--spacing-1-5);
}

.margin-y-1-75 {
  margin-top: var(--spacing-1-75);
  margin-bottom: var(--spacing-1-75);
}

.margin-y-2 {
  margin-top: var(--spacing-2);
  margin-bottom: var(--spacing-2);
}

.margin-y-3 {
  margin-top: var(--spacing-3);
  margin-bottom: var(--spacing-3);
}

.margin-y-4 {
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-4);
}

/** Margin Top */

.margin-top-0-25 {
  margin-top: var(--spacing-0-25);
}

.margin-top-0-5 {
  margin-top: var(--spacing-0-5);
}

.margin-top-1 {
  margin-top: var(--spacing-1);
}

.margin-top-1-25 {
  margin-top: var(--spacing-1-25);
}

.margin-top-1-5 {
  margin-top: var(--spacing-1-5);
}

.margin-top-1-75 {
  margin-top: var(--spacing-1-75);
}

.margin-top-2 {
  margin-top: var(--spacing-2);
}

.margin-top-3 {
  margin-top: var(--spacing-3);
}

.margin-top-4 {
  margin-top: var(--spacing-4);
}

/** Margin Bottom */

.margin-bottom-0-25 {
  margin-bottom: var(--spacing-0-25);
}

.margin-bottom-0-5 {
  margin-bottom: var(--spacing-0-5);
}

.margin-bottom-1 {
  margin-bottom: var(--spacing-1);
}

.margin-bottom-1-25 {
  margin-bottom: var(--spacing-1-25);
}

.margin-bottom-1-5 {
  margin-bottom: var(--spacing-1-5);
}

.margin-bottom-1-75 {
  margin-bottom: var(--spacing-1-75);
}

.margin-bottom-2 {
  margin-bottom: var(--spacing-2);
}

.margin-bottom-3 {
  margin-bottom: var(--spacing-3);
}

.margin-bottom-4 {
  margin-bottom: var(--spacing-4);
}

/** Margin Start */

.margin-start-0-25 {
  margin-left: var(--spacing-0-25);
}

.margin-start-0-5 {
  margin-left: var(--spacing-0-5);
}

.margin-start-1 {
  margin-left: var(--spacing-1);
}

.margin-start-1-25 {
  margin-left: var(--spacing-1-25);
}

.margin-start-1-5 {
  margin-left: var(--spacing-1-5);
}

.margin-start-1-75 {
  margin-left: var(--spacing-1-75);
}

.margin-start-2 {
  margin-left: var(--spacing-2);
}

.margin-start-3 {
  margin-left: var(--spacing-3);
}

.margin-start-4 {
  margin-left: var(--spacing-4);
}

/** Margin End */

.margin-end-0-25 {
  margin-right: var(--spacing-0-25);
}

.margin-end-0-5 {
  margin-right: var(--spacing-0-5);
}

.margin-end-1 {
  margin-right: var(--spacing-1);
}

.margin-end-1-25 {
  margin-right: var(--spacing-1-25);
}

.margin-end-1-5 {
  margin-right: var(--spacing-1-5);
}

.margin-end-1-75 {
  margin-right: var(--spacing-1-75);
}

.margin-end-2 {
  margin-right: var(--spacing-2);
}

.margin-end-3 {
  margin-right: var(--spacing-3);
}

.margin-end-4 {
  margin-right: var(--spacing-4);
}

/** Padding */

.padding-0-25 {
  padding: var(--spacing-0-25);
}

.padding-0-5 {
  padding: var(--spacing-0-5);
}

.padding-1 {
  padding: var(--spacing-1);
}

.padding-1-25 {
  padding: var(--spacing-1-25);
}

.padding-1-5 {
  padding: var(--spacing-1-5);
}

.padding-1-75 {
  padding: var(--spacing-1-75);
}

.padding-2 {
  padding: var(--spacing-2);
}

.padding-3 {
  padding: var(--spacing-3);
}

.padding-4 {
  padding: var(--spacing-4);
}

/** Padding X */

.padding-x-0-25 {
  padding-left: var(--spacing-0-25);
  padding-right: var(--spacing-0-25);
}

.padding-x-0-5 {
  padding-left: var(--spacing-0-5);
  padding-right: var(--spacing-0-5);
}

.padding-x-1 {
  padding-left: var(--spacing-1);
  padding-right: var(--spacing-1);
}

.padding-x-1-25 {
  padding-left: var(--spacing-1-25);
  padding-right: var(--spacing-1-25);
}

.padding-x-1-5 {
  padding-left: var(--spacing-1-5);
  padding-right: var(--spacing-1-5);
}

.padding-x-1-75 {
  padding-left: var(--spacing-1-75);
  padding-right: var(--spacing-1-75);
}

.padding-x-2 {
  padding-left: var(--spacing-2);
  padding-right: var(--spacing-2);
}

.padding-x-3 {
  padding-left: var(--spacing-3);
  padding-right: var(--spacing-3);
}

.padding-x-4 {
  padding-left: var(--spacing-4);
  padding-right: var(--spacing-4);
}

/** Padding Y */

.padding-y-0-25 {
  padding-top: var(--spacing-0-25);
  padding-bottom: var(--spacing-0-25);
}

.padding-y-0-5 {
  padding-top: var(--spacing-0-5);
  padding-bottom: var(--spacing-0-5);
}

.padding-y-1 {
  padding-top: var(--spacing-1);
  padding-bottom: var(--spacing-1);
}

.padding-y-1-25 {
  padding-top: var(--spacing-1-25);
  padding-bottom: var(--spacing-1-25);
}

.padding-y-1-5 {
  padding-top: var(--spacing-1-5);
  padding-bottom: var(--spacing-1-5);
}

.padding-y-1-75 {
  padding-top: var(--spacing-1-75);
  padding-bottom: var(--spacing-1-75);
}

.padding-y-2 {
  padding-top: var(--spacing-2);
  padding-bottom: var(--spacing-2);
}

.padding-y-3 {
  padding-top: var(--spacing-3);
  padding-bottom: var(--spacing-3);
}

.padding-y-4 {
  padding-top: var(--spacing-4);
  padding-bottom: var(--spacing-4);
}

/** Padding Top */

.padding-top-0-25 {
  padding-top: var(--spacing-0-25);
}

.padding-top-0-5 {
  padding-top: var(--spacing-0-5);
}

.padding-top-1 {
  padding-top: var(--spacing-1);
}

.padding-top-1-25 {
  padding-top: var(--spacing-1-25);
}

.padding-top-1-5 {
  padding-top: var(--spacing-1-5);
}

.padding-top-1-75 {
  padding-top: var(--spacing-1-75);
}

.padding-top-2 {
  padding-top: var(--spacing-2);
}

.padding-top-3 {
  padding-top: var(--spacing-3);
}

.padding-top-4 {
  padding-top: var(--spacing-4);
}

/** Padding Bottom */

.padding-bottom-0-25 {
  padding-bottom: var(--spacing-0-25);
}

.padding-bottom-0-5 {
  padding-bottom: var(--spacing-0-5);
}

.padding-bottom-1 {
  padding-bottom: var(--spacing-1);
}

.padding-bottom-1-25 {
  padding-bottom: var(--spacing-1-25);
}

.padding-bottom-1-5 {
  padding-bottom: var(--spacing-1-5);
}

.padding-bottom-1-75 {
  padding-bottom: var(--spacing-1-75);
}

.padding-bottom-2 {
  padding-bottom: var(--spacing-2);
}

.padding-bottom-3 {
  padding-bottom: var(--spacing-3);
}

.padding-bottom-4 {
  padding-bottom: var(--spacing-4);
}

/** Padding Start */

.padding-start-0-25 {
  padding-left: var(--spacing-0-25);
}

.padding-start-0-5 {
  padding-left: var(--spacing-0-5);
}

.padding-start-1 {
  padding-left: var(--spacing-1);
}

.padding-start-1-25 {
  padding-left: var(--spacing-1-25);
}

.padding-start-1-5 {
  padding-left: var(--spacing-1-5);
}

.padding-start-1-75 {
  padding-left: var(--spacing-1-75);
}

.padding-start-2 {
  padding-left: var(--spacing-2);
}

.padding-start-3 {
  padding-left: var(--spacing-3);
}

.padding-start-4 {
  padding-left: var(--spacing-4);
}

/** Padding End */

.padding-end-0-25 {
  padding-right: var(--spacing-0-25);
}

.padding-end-0-5 {
  padding-right: var(--spacing-0-5);
}

.padding-end-1 {
  padding-right: var(--spacing-1);
}

.padding-end-1-25 {
  padding-right: var(--spacing-1-25);
}

.padding-end-1-5 {
  padding-right: var(--spacing-1-5);
}

.padding-end-1-75 {
  padding-right: var(--spacing-1-75);
}

.padding-end-2 {
  padding-right: var(--spacing-2);
}

.padding-end-3 {
  padding-right: var(--spacing-3);
}

.padding-end-4 {
  padding-right: var(--spacing-4);
}

/** Box Shadows */

.box-shadow-sm {
  box-shadow: var(--shade-sm);
}

.box-shadow-md {
  box-shadow: var(--shade-md);
}

.box-shadow-lg {
  box-shadow: var(--shade-lg);
}

.box-shadow-xl {
  box-shadow: var(--shade-xl);
}

@media print {
  .box-shadow-sm {
    -webkit-print-color-adjust: exact;
    /* stylelint-disable-next-line */
    -webkit-filter: opacity(1);
  }

  .box-shadow-md {
    -webkit-print-color-adjust: exact;
    /* stylelint-disable-next-line */
    -webkit-filter: opacity(1);
  }

  .box-shadow-lg {
    -webkit-print-color-adjust: exact;
    /* stylelint-disable-next-line */
    -webkit-filter: opacity(1);
  }

  .box-shadow-xl {
    -webkit-print-color-adjust: exact;
    /* stylelint-disable-next-line */
    -webkit-filter: opacity(1);
  }
}

/** Border radius */

.border-radius-xs {
  border-radius: var(--radius-xs);
}

.border-radius-sm {
  border-radius: var(--radius-sm);
}

.border-radius-md {
  border-radius: var(--radius-md);
}

.border-radius-lg {
  border-radius: var(--radius-lg);
}

.border-radius-xl {
  border-radius: var(--radius-xl);
}

.border-radius-xxl {
  border-radius: var(--radius-xxl);
}

/** Position */

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-relative {
  position: relative;
}

.position-sticky {
  position: sticky;
}

/* Overflow styles */

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-visible {
  overflow: visible;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-y-visible {
  overflow-y: visible;
}

/** Flex attributes */

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

/** Break properties */

.break-before-avoid {
  break-before: avoid;
}

.break-before-auto {
  break-before: auto;
}

.break-before-always {
  break-before: page;
}

.break-after-avoid {
  break-after: avoid;
}

.break-after-auto {
  break-after: auto;
}

.break-after-always {
  break-after: page;
}

.break-inside-avoid {
  break-inside: avoid;
}

.break-inside-auto {
  break-inside: auto;
}
