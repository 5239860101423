.svg {
  flex-shrink: 0;
  vertical-align: middle;
}

.svg.palette {
  background-color: transparent;
}

.pill {
  display: inline-flex;
  padding: var(--spacing-1);
  background-color: var(--color-charcoal-5);
  border-radius: var(--radius-sm);
}

.palette {
  background-color: var(--background-color);
  color: var(--color-primary);
}

.plusSvg {
  color: white;
}

.plusPill {
  background: url('./plus_gradient.webp') center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}
