.reset {
  margin: 0;
  padding: 0;
  color: inherit;
}

.inter {
  font-family: inter, serif;
}

.instrument {
  font-family: instrument, serif;
}

.h1,
.display1 {
  font-size: 28px;
  letter-spacing: -0.5px;
  line-height: 1.25;
}

.h2,
.display2 {
  font-size: 24px;
  letter-spacing: -0.4px;
  line-height: 1.25;
}

.h3,
.display3 {
  font-size: 22px;
  letter-spacing: -0.4px;
  line-height: 1.25;
}

.h4,
.display4 {
  font-size: 20px;
  letter-spacing: -0.4px;
  line-height: 1.25;
}

.h5,
.display5 {
  font-size: 18px;
  letter-spacing: -0.4px;
  line-height: 1.25;
}

.h6,
.display6 {
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 1.25;
}

.pLarge {
  font-size: 16px;
  letter-spacing: -0.3px;
  line-height: 1.3;
}

.pMedium {
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 1.3;
}

.pSmall {
  font-size: 12px;
  line-height: 1.3;
}

.caption {
  font-size: 10px;
  line-height: 1.3;
}

.bold {
  font-weight: 700;
}

.semi {
  font-weight: 600;
}

.medium {
  font-weight: 500;
}

.normal {
  font-weight: 400;
}

.start {
  text-align: start;
}

.center {
  text-align: center;
}

.end {
  text-align: end;
}

.colorMute {
  color: var(--color-text-muted);
}

/** This is a hack to make muted text darker when using burn blend mode. */
.colorBurn.colorMute {
  color: var(--color-charcoal-80);
}

.colorLight {
  color: var(--color-text-light);
}

.colorDark {
  color: var(--color-text);
}

.colorBrand {
  color: var(--color-brand);
}

.colorPrimary {
  color: var(--color-primary);
}

.colorSecondary {
  color: var(--color-secondary);
}

.colorError {
  color: var(--color-error);
}

.colorSuccess {
  color: var(--color-fern-dark);
}

.colorInherit {
  color: inherit;
}

.breakAll {
  word-break: break-all;
}

.breakWord {
  word-break: break-word;
}

.breakKeepAll {
  word-break: keep-all;
}

.breakNormal {
  word-break: normal;
}

.nowrap {
  white-space: nowrap;
}

.colorBurn {
  mix-blend-mode: color-burn;
}

.truncate {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.lineClamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.italic {
  font-style: italic;
}
