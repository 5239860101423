/**
  * THEMATIC VARIABLES
  * -------------------
  * These are contextually overwritten in order to customize the look and feel of components.
  */
.default {
  /**
   * Default background color.
   * eg: background color of a sheet, modal or page.
   */
  --background-color: var(--color-white);

  /**
   * Complementary/contrasting background color.
   * eg: The background color of a section of a group or area within a page, modal, or sheet.
   */
  --background-color-secondary: var(--color-blueViolet-light);

  /**
   * Light background color.
   * Note iOS 15 can't seem to handle linear gradients with alpha stops, so we use a solid color for the light background.
   */
  --background-color-light: var(--color-blueViolet-light);

  /**
   * Default text color.
   */
  --color-text: var(--color-charcoal-90);

  /**
   * Muted text color.
   * eg: subdued text like captions.
   */
  --color-text-muted: var(--color-charcoal-60);

  /**
   * Border color
   * e.g. used for checkbox toggle
   */
  --border-color: var(--color-charcoal-alpha-30);

  /**
   * Light text color. Used to set a text color that is lighter than the background.
   * Eg: Text on solid dark color.
   */
  --color-text-light: var(--color-white);

  /**
   * Brand color is used for highlighting primary cta's independent of thematic context.
   */
  --color-brand: var(--color-violet-strong);

  /**
   * Primary color is used for coloring icons, text, borders etc, with primary emphasis in a given context.
   * eg: The outline of an icon, or fill of a primary button.
   */
  --color-primary: var(--color-violet-strong);

  /**
   * Color used for tinting the primary color slightly darker.
   * eg: Hover and focus effects
   */
  --color-primary-tint-dark: var(--color-violet-dark);

  /**
   * Secondary color is used to provide a secondary complementary color to the given primary.
   * eg: The fill and background of icons. Or muted text.
   */
  --color-secondary: var(--color-violet-light);

  /**
   * Tertiary color is used in less often to provide an additional contrasting or complementary color to primary and secondary colors.
   * eg: The accent color within icons, setting a section of an icon white or transparent.
   */
  --color-tertiary: transparent;

  /**
   * Error color is used to direct users towards interactive elements that have failed validation
   * eg: error messages during login
   */
  --color-error: var(--color-salmon-strong);
}

.violet {
  --background-color: var(--color-violet-light);
  --background-color-secondary: var(--color-white);
  --background-color-light: var(--color-violet-extra-light);
  --color-text: var(--color-violet-strong);
  --color-text-muted: var(--color-pink-light);
  --border-color: var(--color-violet-strong);
  --color-text-light: var(--color-white);
  --color-primary: var(--color-violet-strong);
  --color-primary-alpha-10: var(--color-violet-alpha-10);
  --color-primary-alpha-30: var(--color-violet-alpha-30);
  --color-secondary: var(--color-violet-light);
  --color-tertiary: transparent;
}

.blueViolet {
  --background-color: var(--color-blue-light);
  --background-color-secondary: var(--color-white);
  --background-color-light: var(--color-blue-extra-light);
  --color-text: var(--color-violet-strong);
  --color-text-muted: var(--color-violet-light);
  --color-text-light: var(--color-white);
  --color-primary: var(--color-violet-strong);
  --color-primary-alpha-10: var(--color-violet-alpha-10);
  --color-primary-alpha-30: var(--color-violet-alpha-30);
  --color-primary-tint-dark: var(--color-violet-dark);
  --color-secondary: var(--color-violet-light);
  --color-tertiary: transparent;
}

.blue {
  --background-color: var(--color-blue-light);
  --background-color-secondary: var(--color-white);
  --background-color-light: var(--color-blue-extra-light);
  --color-text: var(--color-blue-strong);
  --color-text-muted: var(--color-blue-light);
  --border-color: var(--color-blue-strong);
  --color-text-light: var(--color-white);
  --color-primary: var(--color-blue-strong);
  --color-primary-alpha-10: var(--color-blue-alpha-10);
  --color-primary-alpha-30: var(--color-blue-alpha-30);
  --color-primary-tint-dark: var(--color-blue-dark);
  --color-secondary: var(--color-blue-light);
  --color-tertiary: transparent;
}

.turquoise {
  --background-color: var(--color-turquoise-light);
  --background-color-secondary: var(--color-white);
  --background-color-light: var(--color-turquoise-extra-light);
  --color-text: var(--color-turquoise-strong);
  --color-text-muted: var(--color-turquoise-light);
  --border-color: var(--color-turquoise-strong);
  --color-text-light: var(--color-white);
  --color-primary: var(--color-turquoise-strong);
  --color-primary-alpha-10: var(--color-turquoise-alpha-10);
  --color-primary-alpha-30: var(--color-turquoise-alpha-30);
  --color-primary-tint-dark: var(--color-turquoise-dark);
  --color-secondary: var(--color-turquoise-light);
  --color-tertiary: transparent;
}

.fern {
  --background-color: var(--color-fern-light);
  --color-text: var(--color-fern-strong);
  --color-text-muted: var(--color-fern-light);
  --border-color: var(--color-fern-strong);
  --color-text-light: var(--color-white);
  --color-primary: var(--color-fern-strong);
  --color-primary-alpha-10: var(--color-fern-alpha-10);
  --color-primary-alpha-30: var(--color-fern-alpha-30);
  --color-primary-tint-dark: var(--color-fern-dark);
  --color-secondary: var(--color-fern-light);
  --color-tertiary: transparent;
}

.honey {
  --background-color: var(--color-honey-light);
  --color-text: var(--color-honey-strong);
  --color-text-muted: var(--color-honey-light);
  --border-color: var(--color-honey-strong);
  --color-text-light: var(--color-white);
  --color-primary: var(--color-honey-strong);
  --color-primary-alpha-10: var(--color-honey-alpha-10);
  --color-primary-alpha-30: var(--color-honey-alpha-30);
  --color-primary-tint-dark: var(--color-honey-dark);
  --color-secondary: var(--color-honey-light);
  --color-tertiary: transparent;
}

.orangeHoney {
  --background-color: var(--color-honey-light);
  --background-color-secondary: var(--color-white);
  --background-color-light: var(--color-honey-extra-light);
  --color-text: var(--color-orange-strong);
  --color-text-muted: var(--color-orange-light);
  --border-color: var(--color-orange-strong);
  --color-text-light: var(--color-white);
  --color-primary: var(--color-orange-strong);
  --color-primary-alpha-10: var(--color-orange-alpha-10);
  --color-primary-alpha-30: var(--color-orange-alpha-30);
  --color-primary-tint-dark: var(--color-orange-dark);
  --color-secondary: var(--color-honey-light);
  --color-tertiary: transparent;
}

.orange {
  --background-color: var(--color-orange-light);
  --color-text: var(--color-orange-strong);
  --color-text-muted: var(--color-orange-light);
  --border-color: var(--color-orange-strong);
  --color-text-light: var(--color-white);
  --color-primary: var(--color-orange-strong);
  --color-primary-alpha-10: var(--color-orange-alpha-10);
  --color-primary-alpha-30: var(--color-orange-alpha-30);
  --color-primary-tint-dark: var(--color-orange-dark);
  --color-secondary: var(--color-orange-light);
  --color-tertiary: transparent;
}

.salmon {
  --background-color: var(--color-salmon-light);
  --color-text: var(--color-salmon-strong);
  --color-text-muted: var(--color-salmon-light);
  --border-color: var(--color-salmon-strong);
  --color-text-light: var(--color-white);
  --color-primary: var(--color-salmon-strong);
  --color-primary-alpha-10: var(--color-salmon-alpha-10);
  --color-primary-alpha-30: var(--color-salmon-alpha-30);
  --color-primary-tint-dark: var(--color-salmon-dark);
  --color-secondary: var(--color-salmon-light);
  --color-tertiary: transparent;
}

.pink {
  --background-color: var(--color-salmon-light);
  --background-color-secondary: var(--color-white);
  --background-color-light: var(--color-salmon-extra-light);
  --color-text: var(--color-pink-strong);
  --color-text-muted: var(--color-pink-light);
  --border-color: var(--color-pink-strong);
  --color-text-light: var(--color-white);
  --color-primary: var(--color-pink-strong);
  --color-primary-alpha-10: var(--color-pink-alpha-10);
  --color-primary-alpha-30: var(--color-pink-alpha-30);
  --color-primary-tint-dark: var(--color-pink-dark);
  --color-secondary: var(--color-pink-light);
  --color-tertiary: transparent;
}

.error {
  --background-color: var(--color-salmon-strong);
  --color-text: var(--color-white);
  --color-text-muted: var(--color-salmon-light);
  --border-color: var(--color-salmon-strong);
  --color-text-light: var(--color-white);
  --color-primary: var(--color-salmon-strong);
  --color-primary-alpha-10: var(--color-salmon-alpha-10);
  --color-primary-alpha-30: var(--color-salmon-alpha-30);
  --color-primary-tint-dark: var(--color-salmon-dark);
  --color-secondary: var(--color-salmon-light);
  --color-tertiary: transparent;
}

.charcoal {
  --background-color: var(--color-charcoal-10);
  --color-text: var(--color-charcoal-60);
  --color-text-muted: var(--color-charcoal-10);
  --border-color: var(--color-charcoal-60);
  --color-text-light: var(--color-white);
  --color-primary: var(--color-charcoal-60);
  --color-primary-alpha-10: var(--color-charcoal-alpha-10);
  --color-primary-alpha-30: var(--color-charcoal-alpha-30);
  --color-primary-tint-dark: var(--color-charcoal-80);
  --color-secondary: var(--color-charcoal-10);
  --color-tertiary: transparent;
}
